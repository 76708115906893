import axios from 'axios'
import {tokenApi} from "@/api/CoreApi";



const SELLER_DOMAIN = process.env.VUE_APP_API_HOST



const sellerDomain = axios.create({
    baseURL: SELLER_DOMAIN,
    headers: {
        post: {        // can be common or any other method
            "Content-Type": "application/json;charset=UTF-8"
        },
        'Accept': 'application/json;charset=UTF-8'
    }
})


export const accountApi = {
    registerSellerMember(member){
        return sellerDomain.post('/api/v1/seller/register',member,null).then( result =>{
            const res = result.data;
            return res
        }).catch( result => {
            throw result.response
        })
    },
    getMyInfo(){
        return tokenApi.get('/api/v1/sellers/me').then( result =>{
            const res = result.data;
            if( res.resCode === '0000'){
                return res.data
            }else{
                throw result.response
            }
        }).catch( result => {
            console.error(result.response)
            throw result.response
        })
    },
    initializeSeller(){
        return tokenApi.post('/api/v1/seller/initialize').then( result =>{
            console.log('initializeSeller',result)
        }).catch( result => {
            console.error(result.response)
        })
    },
}




