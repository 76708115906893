<template>
  <section class="max-w-1200-wrap" ref="container">
    <div class="max-w-1200">
      <h3>
        간편링크로 PC/모바일/<br class="hidden-lg" />SNS 채널<br />
        <strong>구분없이 판매</strong>하세요!
      </h3>
      <ul>
        <li>
          <div>
            <span>
              <img
                class="phone"
                src="../../../assets/home-new/app-1.png"
                alt=""
                loading="lazy"
              />
            </span>
            <span>
              <img
                class="phone"
                src="../../../assets/home-new/app-2.png"
                alt=""
                loading="lazy"
              />
            </span>
          </div>
          <p>
            메신저 카카오톡 or SNS에<br />
            주문서 URL 및 쇼핑몰 <strong>주소 제공</strong>
          </p>
        </li>
        <li>
          <div>
            <span class="phone-click">
              <img
                class="phone"
                src="../../../assets/home-new/app-3.png"
                alt=""
                loading="lazy"
              />
              <img
                class="click"
                src="../../../assets/home-new/click.png"
                alt=""
                loading="lazy"
              />
              <img
                class="pointer"
                src="../../../assets/home-new/pointer-hand.png"
                alt=""
                loading="lazy"
              />
              <button type="button" @click="openLink1">쇼핑몰 샘플</button>
            </span>
            <span>
              <img
                class="phone"
                src="../../../assets/home-new/app-4.png"
                alt=""
                loading="lazy"
              />
              <button type="button" @click="openLink2">주문서 샘플</button>
            </span>
          </div>
          <p>
            결제창 or 나만의 쇼핑몰로<br />
            <strong>바로 이동</strong>
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { gsap } from "gsap";
export default {
  mounted() {
    gsap.to(this.$refs.container.querySelector(".pointer"), {
      scrollTrigger: {
        trigger: this.$refs.container.querySelector("ul li:last-child"),
        toggleActions: "play none none reset",
      },
      opacity: 1,
      y: 0,
      x: 0,
      duration: 0.5,
      delay: 0.5,
    });
  },
  methods: {
    openLink1() {
      window.open("https://shop.weselpay.com/weselpay2", "_blank");
    },
    openLink2() {
      window.open("https://shop.weselpay.com/p/dQ", "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
.renew {
  .max-w-1200-wrap {
    background-color: #f3fbfc;
    padding-top: 145px;
    padding-bottom: 139px;
  }

  .max-w-1200 h3 {
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 55px;
    color: #424242;
  }

  .max-w-1200 ul {
    display: flex;
    gap: 40px;
    max-width: calc(100vw - 152px);
    margin-right: -320px;
  }

  .max-w-1200 ul li p {
    text-align: center;
    margin-top: 66px;
    font-size: 24px;
    line-height: 28px;
  }

  .max-w-1200 ul li > div {
    display: flex;
    gap: 40px;
  }

  .max-w-1200 ul li > div button {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    bottom: 46px;
    left: 15px;
    width: calc(100% - 30px);
    height: 50px;
  }

  .max-w-1200 ul li > div span {
    position: relative;
  }

  .click {
    position: absolute;
    width: 103px;
    height: auto;
    bottom: -71px;
    left: -13px;
  }

  .pointer {
    position: absolute;
    width: 87px;
    height: auto;
    bottom: 1px;
    right: 8px;
    opacity: 0;
    transform: translate(50px, 50px);
  }

  .phone {
    width: 100%;
    height: auto;
    filter: drop-shadow(-10px 40px 20px rgba(0, 0, 0, 0.25));
  }

  @media screen and (max-width: 1312px) {
    .max-w-1200-wrap {
      padding-top: 11.052vw;
      padding-bottom: 10.595vw;
    }

    .max-w-1200 h3 {
      font-size: 3.659vw;
      margin-bottom: 4.192vw;
    }

    .max-w-1200 ul {
      gap: 3.049vw;
      max-width: 100%;
      margin-right: -24.39vw;
    }

    .max-w-1200 ul li p {
      margin-top: 5.03vw;
      font-size: 1.829vw;
      line-height: 2.134vw;
    }

    .max-w-1200 ul li > div {
      gap: 3.049vw;
    }

    .max-w-1200 ul li > div button {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      bottom: 3.506vw;
      left: 1.143vw;
      width: calc(100% - 2.287vw);
      height: 3.811vw;
    }

    .click {
      width: 7.851vw;
      bottom: -5.412vw;
      left: -0.991vw;
    }

    .pointer {
      width: 6.631vw;
      right: 0.61vw;
    }

    .phone {
      filter: drop-shadow(-0.762vw 3.049vw 1.524vw rgba(0, 0, 0, 0.25));
    }
  }

  @media screen and (max-width: 650px) {
    .max-w-1200-wrap {
      padding-top: 16vw;
      padding-bottom: 20vw;
    }

    .max-w-1200 h3 {
      font-size: 6.4vw;
      margin-bottom: 18.667vw;
    }

    .max-w-1200 ul {
      flex-direction: column;
      gap: 20.8vw;
      margin-right: 0;
    }

    .max-w-1200 ul li p {
      margin-top: 6.6vw;
      font-size: 3.733vw;
      line-height: 4.267vw;
    }

    .max-w-1200 ul li > div {
      gap: 3.333vw;
    }

    .max-w-1200 ul li > div button {
      bottom: 7.733vw;
      left: 2.4vw;
      width: calc(100% - 4.8vw);
      height: 7.467vw;
    }

    .click {
      width: 12.533vw;
      bottom: -8.533vw;
      left: -1px;
    }

    .pointer {
      width: 11.6vw;
      right: 1px;
      bottom: -1.067vw;
      transform: translate(0, 0);
    }

    .phone {
      filter: drop-shadow(-2.667vw 5.333vw 5.333vw rgba(0, 0, 0, 0.3));
    }
  }
}
</style>