<template>
  <div class="renew">
    <EventSection1 />
    <EventSection2 />
    <EventSection3 />
    <EventSection4 />
    <EventSection5 />
  </div>
</template>

<script>
import EventSection1 from "./components/EventSection1.vue";
import EventSection2 from "./components/EventSection2.vue";
import EventSection3 from "./components/EventSection3.vue";
import EventSection4 from "./components/EventSection4.vue";
import EventSection5 from "./components/EventSection5.vue";

export default {
  components: {
    EventSection1,
    EventSection2,
    EventSection3,
    EventSection4,
    EventSection5,
  },
};
</script>
