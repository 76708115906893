<template>
  <div>
    <div class="text-center">
      <v-dialog
          v-model="dialog"
          width="640"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            공지사항
          </v-card-title>

          <v-card-text style="padding: 20px;margin-top: 25px">
            <p style="line-height: 40px;font-size: 17px">
              곧 오픈 예정이나 현재 서비스는 테스트 중인 서비스입니다.<br>
              가입 및 이용이 불가능한 상태이니,<br>
              문의 사항이 있으시면 관리자에게 문의 부탁드립니다.<br>
              관리자 메일 주소 : <span style="font-weight: bold;color: #428DB1">admin@weselpay.com</span>
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainWebNotice",
  created() {
    console.log('MainWebNotice 생성')
    //this.dialog=true
  },
  data(){
    return {
      dialog: false
    }
  }
}
</script>

<style scoped>

</style>