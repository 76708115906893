<template>
  <v-app>
    <HeaderNew :white="$route.path === '/event'" :fixed="$route.path !== '/event' && $route.path !== '/'" />
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <Footer />
    <MainWebNotice />
  </v-app>
</template>

<script>
import HeaderNew from "./HeaderNew";
import Footer from "./Footer";
import MainWebNotice from "./MainWebNotice";
const { accountApi } = require("@/api/Account");

export default {
  name: "Layout",

  components: {
    HeaderNew,
    Footer,
    MainWebNotice,
  },
  created() {
    const isAuthenticated = this.$store.getters.isAuthenticated;
    if (isAuthenticated === true) {
      const myInfo = this.$store.getters.getMyInfo;
      if (myInfo === null) {
        accountApi.getMyInfo().then((res) => {
          this.$store.commit("setMyInfo", res);
        });
      }
    }
  },
};
</script>